<template>
  <div class="app-container">
    <avue-crud
      ref="crud"
      class="flex-one-page"
      :option="tableOption"
      :data="finalData"
      :page="tablePage"
      :tableLoading="tableLoading"
      :span-method="spanMethod"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
      @selection-change="selectionChange"
    >
      <template #menuLeft>
        <loadingBtn
          v-p="['externaladmin:user:viewKnife:batchDownload']"
          type="primary"
          size="mini"
          class="mb20"
          @click="batchDownloadBasicImage"
          >批量下载刀版图</loadingBtn
        >
        <loadingBtn
          v-p="['externaladmin:user:viewKnife:batchCreatePicturePath']"
          type="primary"
          size="mini"
          class="mb20"
          @click="batchCreatePicturePath"
          >批量生成效果图</loadingBtn
        >
      </template>
      <template #multiSearchSearchForm="{ form }">
        <multiSearch @handleSearch="searchChange" :basicStyle="true" style="margin-top: -49px" :query="form" />
      </template>
      <template #originalMerge="{ row }">
        <defaultImg :content="row.chineseName" :carouselList="originalPicture(row)"></defaultImg>
      </template>
      <template #create_timeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <template #createByName="{ row }">
        <span v-if="row.mainUser">{{ `${row.mainUser} - ${row.createByName}` }}</span>
        <span v-else>{{ row.createByName || '暂无' }}</span>
      </template>
      <template #knife="{ row }">
        <defaultImg :carouselList="knifeImageList(row)" :contentList="formatName(row)" :noUseCache="true"></defaultImg>
      </template>
      <template #picture="{ row }">
        <defaultImg :carouselList="concatBottomBoardShowImages(row)" :content="row.chineseName"></defaultImg>
      </template>
      <template #menu="{ row }">
        <ColorTextBtn style="font-size: 14px" v-copy="originalPictures(row) && originalPictures(row).join('，')"
          >复制原图</ColorTextBtn
        >
        <ColorTextBtn style="font-size: 14px" v-copy="knifeImageList(row) && knifeImageList(row).join('，')"
          >复制</ColorTextBtn
        >
        <el-divider direction="vertical"></el-divider>
        <loadingBtn type="text" @click="downloadKnifeImage(row)" v-p="['externaladmin:user:viewKnife:download']"
          >下载刀版图</loadingBtn
        >
        <el-divider direction="vertical"></el-divider>
        <loadingBtn type="text" @click="createKnifePath(row)" v-p="['externaladmin:user:viewKnife:generate']">
          {{ knifeImageList(row).filter(Boolean).length ? '更新' : '生成' }}刀版图
        </loadingBtn>
        <el-divider direction="vertical"></el-divider>
        <loadingBtn type="text" @click="createPicturePath(row)" v-p="['externaladmin:user:viewKnife:generatePicture']">
          {{ row.$clearPath ? '更新' : '生成' }}效果图
        </loadingBtn>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import multiSearch from '@/views/picture/basePicListPage/module/multiSearch'
import { tableOption } from './const'
import { knifeList as getList, testAlgo, testAlgoShowImg } from '@/api/product/knifeProductApi'
import SaleDateTime from '@/components/saleDateTime'
import defaultImg from '@/views/components/defaultImg'
import { parseTime, createRandomNum, requestLatest } from '@/utils'
import { downloadImageByAixos as downloadImage } from '@/utils/download'
import { validatenull } from '@/components/avue/utils/validate'
import { downloadByZip } from '@/utils/download'
import { mapGetters } from 'vuex'
import { map, isArray, uniq } from 'lodash'

export default {
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  components: {
    SaleDateTime,
    defaultImg,
    multiSearch
  },
  data() {
    return {
      finalData: [],
      selectData: []
    }
  },
  computed: {
    ...mapGetters(['is_super']),

    //常规效果图合并组合效果图
    concatBottomBoardShowImages() {
      return (row) => {
        try {
          const { bottomBoardShowImageList, $clearPath } = row
          return [...($clearPath || []), ...(bottomBoardShowImageList || []).map(({ clearPath }) => clearPath)]
        } catch {
          return []
        }
      }
    },
    originalPicture() {
      return (row) => {
        try {
          if (row.$imageList) return row.$imageList
          if (!row) return
          let picList = []
          if (!row.imageList) return
          row.imageList.map((item) => {
            picList.push(item.thumbnailPath)
          })
          // picList = picList.map((item, index) => ({
          //   path: item,
          //   currentImageIndex: index
          // }))
          return (row.$imageList = picList)
        } catch (error) {}
      }
    },
    knifeImageList() {
      return (row) => map(row.knifeLayoutList, 'knifeLayoutImagePath').filter(Boolean).map(requestLatest)
    },

    formatName() {
      return (row) => {
        return row?.knifeLayoutList?.map(({ knifeLayoutImagePath }) => {
          const path = knifeLayoutImagePath || ''
          return path.split('/').slice(-1)?.[0]
        })
      }
    },

    originalPictures() {
      return (row) => {
        try {
          if (row.$originalImageList) return row.$originalImageList
          if (!row) return
          let picList = []
          if (!row.imageList) return
          row.imageList.map((item) => {
            picList.push(item.path)
          })
          // picList = picList.map((item, index) => ({
          //   path: item,
          //   currentImageIndex: index
          // }))
          return (row.$originalImageList = picList)
        } catch (error) {}
      }
    },
    validateRow({ searchForm: { sys_sku, custom_sku, is } }) {
      let sysSkuReg, customSkuReg
      if (!validatenull(sys_sku)) sysSkuReg = new RegExp(sys_sku.trim(), 'img')
      if (!validatenull(custom_sku)) customSkuReg = new RegExp(custom_sku.trim(), 'img')
      return (row) => {
        const hasKnifeImage = this.knifeImageList(row).length
        if (is === 0 && hasKnifeImage) return false
        if (is === 1 && !hasKnifeImage) return false
        if (sysSkuReg) {
          sysSkuReg.lastIndex = 0
          if (!sysSkuReg.test(row.SKU)) return false
        }
        if (customSkuReg) {
          customSkuReg.lastIndex = 0
          if (!customSkuReg.test(row.custom_sku)) return false
        }
        return true
      }
    }
  },
  methods: {
    selectionChange(selData) {
      this.selectData = selData
    },
    beforeInit() {
      this.finalData = []
    },
    afterInit() {
      this.getData()
    },
    getData() {
      let { tableData, validateRow } = this
      let tempArr = []
      tableData.forEach((item, index) => {
        // let defaultClearPath = getValueFromObj(item, 'customShowImageList[0].clearPath')
        let spec_prods = item.customSpecificProductList || []
        spec_prods = spec_prods.filter((row) => validateRow(row))
        spec_prods.forEach((row, rowIndex) => {
          row.$clearPath = item.customShowImageList?.map(({ clearPath }) => clearPath)
          Object.assign(row, item, {
            customSpecificId: row.id
          })
          row.$knifeIndex = index
          row.$rowIndex = rowIndex
          row.$knifeLen = spec_prods.length
          row.createTime = parseTime(row.createTime)
          tempArr.push(row)
        })
      })
      this.finalData = tempArr
    },

    //使用迅雷下载
    downloadByPath(paths) {
      const tasks = paths.map((url) => {
        return {
          name: '',
          url: `${url}?uid=${createRandomNum()}`
        }
      })
      thunderLink.newTask({
        downloadDir: '', // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
        tasks
      })
    },

    /**
     * 检验选中数据是否存在可以下载的刀版图
     * @param data
     * @returns {boolean|*}
     */
    checkKnifeLayoutImagePath(data) {
      if (!isArray(data)) return false
      return data.some((item) => {
        const customSpecificProductList = $GET(item, 'customSpecificProductList', [])
        return customSpecificProductList.some((sItem) => !!this.knifeImageList(sItem).length)
      })
    },

    async batchDownloadBasicImage() {
      if (!this.selectData.length) {
        this.$message.warning('请先选择需要批量下载的刀版图')
        return
      }
      if (!this.checkKnifeLayoutImagePath(this.selectData)) {
        return this.$message.warning('刀版图未生成，没有有效的下载数据')
      }

      let paths = []
      this.selectData.map(({ customSpecificProductList }) => {
        customSpecificProductList.forEach(({ sku, knifeLayoutList }) => {
          knifeLayoutList?.forEach(({ knifeLayoutImagePath: url }, index) => {
            if (url) {
              paths.push({
                url,
                name: `${sku}--${index + 1}`,
                type: 'upload',
                status: '0',
                thumbnailPath: '',
                loaded: 0,
                total: 0.000001
              })
            }
          })
        })
      })
      return downloadByZip(paths)
    },
    downloadKnifeImage({ sku, knifeLayoutList }) {
      let paths = []
      knifeLayoutList?.forEach(({ knifeLayoutImagePath: url }, index) => {
        if (url) {
          paths.push({
            url,
            name: `${sku}--${index + 1}`,
            type: 'upload',
            status: '0',
            thumbnailPath: '',
            loaded: 0,
            total: 0.000001
          })
        }
      })
      if (!paths.length) {
        return this.$message.warning('刀版图未生成，没有有效的下载数据')
      }
      if (paths.length === 1) {
        return downloadImage(paths[0].url, sku)
      }
      return downloadByZip(paths)
    },
    async createKnifePath(row) {
      console.log('row', row)
      let res = await awaitResolve(
        testAlgo({
          customSpecificProductId: row.customSpecificId
        })
      )
      if (res) {
        this.$message.success('生成成功')
        this.refreshPage()
      }
    },
    async createPicturePath(row, isInit = true) {
      let res = await awaitResolve(
        testAlgoShowImg({
          customProductId: row.id
        })
      )
      if (res && isInit) {
        this.$message.success('生成成功')
        this.refreshPage()
      }
    },
    async batchCreatePicturePath() {
      if (!this.selectData.length) {
        this.$message.warning('请先选择需要批量生成的效果图')
        return
      }
      await promiseAll(
        uniq(map(this.selectData, 'id'))
          .map((id) => this.createPicturePath({ id }, false))
      )
      this.$message.success('生成成功')
      this.refreshPage()
    },

    spanMethod({ row, columnIndex }) {
      if ([0, 1].includes(columnIndex)) {
        if (row.$rowIndex === 0) {
          return [row.$knifeLen, 1]
        } else {
          return [0, 0]
        }
      }
      return [1, 1]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  ::v-deep {
    .el-table__fixed-right {
      z-index: 10;
    }
    .el-carousel__arrow.el-carousel__arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 30px;
      width: 30px;
      cursor: pointer;
      transition: 0.3s;
      color: #fff;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      color: #cdcdcd;
      font-weight: 600;
      background: none;
      left: 0;
      font-size: 20px;
    }
    .el-form-item {
      margin-top: 23px;
    }
    .search-form-confirm {
      margin: 0px 0 0 18px;
    }
    .el-carousel__arrow--left.el-carousel__arrow--left {
      left: -10px;
    }
    .el-carousel__arrow--right.el-carousel__arrow--right {
      left: auto;
      right: -10px;
    }
  }
}
</style>
