var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('avue-crud',{ref:"crud",staticClass:"flex-one-page",attrs:{"option":_vm.tableOption,"data":_vm.finalData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading,"span-method":_vm.spanMethod},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange,"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:user:viewKnife:batchDownload']),expression:"['externaladmin:user:viewKnife:batchDownload']"}],staticClass:"mb20",attrs:{"type":"primary","size":"mini"},on:{"click":_vm.batchDownloadBasicImage}},[_vm._v("批量下载刀版图")]),_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:user:viewKnife:batchCreatePicturePath']),expression:"['externaladmin:user:viewKnife:batchCreatePicturePath']"}],staticClass:"mb20",attrs:{"type":"primary","size":"mini"},on:{"click":_vm.batchCreatePicturePath}},[_vm._v("批量生成效果图")])]},proxy:true},{key:"multiSearchSearchForm",fn:function(ref){
var form = ref.form;
return [_c('multiSearch',{staticStyle:{"margin-top":"-49px"},attrs:{"basicStyle":true,"query":form},on:{"handleSearch":_vm.searchChange}})]}},{key:"originalMerge",fn:function(ref){
var row = ref.row;
return [_c('defaultImg',{attrs:{"content":row.chineseName,"carouselList":_vm.originalPicture(row)}})]}},{key:"create_timeSearchForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
return [_c('SaleDateTime',{attrs:{"time":form[prop]},on:{"update:time":function($event){return _vm.$set(form, prop, $event)},"change":_vm.searchChange}})]}},{key:"createByName",fn:function(ref){
var row = ref.row;
return [(row.mainUser)?_c('span',[_vm._v(_vm._s(((row.mainUser) + " - " + (row.createByName))))]):_c('span',[_vm._v(_vm._s(row.createByName || '暂无'))])]}},{key:"knife",fn:function(ref){
var row = ref.row;
return [_c('defaultImg',{attrs:{"carouselList":_vm.knifeImageList(row),"contentList":_vm.formatName(row),"noUseCache":true}})]}},{key:"picture",fn:function(ref){
var row = ref.row;
return [_c('defaultImg',{attrs:{"carouselList":_vm.concatBottomBoardShowImages(row),"content":row.chineseName}})]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('ColorTextBtn',{directives:[{name:"copy",rawName:"v-copy",value:(_vm.originalPictures(row) && _vm.originalPictures(row).join('，')),expression:"originalPictures(row) && originalPictures(row).join('，')"}],staticStyle:{"font-size":"14px"}},[_vm._v("复制原图")]),_c('ColorTextBtn',{directives:[{name:"copy",rawName:"v-copy",value:(_vm.knifeImageList(row) && _vm.knifeImageList(row).join('，')),expression:"knifeImageList(row) && knifeImageList(row).join('，')"}],staticStyle:{"font-size":"14px"}},[_vm._v("复制")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:user:viewKnife:download']),expression:"['externaladmin:user:viewKnife:download']"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadKnifeImage(row)}}},[_vm._v("下载刀版图")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:user:viewKnife:generate']),expression:"['externaladmin:user:viewKnife:generate']"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.createKnifePath(row)}}},[_vm._v(" "+_vm._s(_vm.knifeImageList(row).filter(Boolean).length ? '更新' : '生成')+"刀版图 ")]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:user:viewKnife:generatePicture']),expression:"['externaladmin:user:viewKnife:generatePicture']"}],attrs:{"type":"text"},on:{"click":function($event){return _vm.createPicturePath(row)}}},[_vm._v(" "+_vm._s(row.$clearPath ? '更新' : '生成')+"效果图 ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }